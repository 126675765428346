<script setup>
useHead({
  htmlAttrs: {
    class: "2xl:text-[20px]",
    dir: "ltr",
    lang: "en"
  },
  bodyAttrs: {
    class: "antialiased bg-page text-default tracking-tight",
  },
})

useSeoMeta({
  title: 'TGI - Protect What Matters',
  description: 'Protect what matters most with TGI. More than just insurance, we offer you a peace of mind and financial security.',
  ogTitle: 'TGI - Protect What Matters',
  ogDescription: 'Protect what matters most with TGI. More than just insurance, we offer you a peace of mind and financial security.',
  ogImage: '[og:image]',
  ogUrl: 'https://transglobalinsurance.ca',
  twitterTitle: 'TGI - Protect What Matters',
  twitterDescription: 'Protect what matters most with TGI. More than just insurance, we offer you a peace of mind and financial security.',
  twitterImage: '[twitter:image]',
  twitterCard: 'summary'
})

onMounted(async () => {
  // client-side JS
  if (typeof window !== 'undefined') {

    function attachEvent(selector, event, fn) {
      const matches = typeof selector === 'string' ? document.querySelectorAll(selector) : selector;
      if (matches && matches.length) {
        matches.forEach((elem) => {
          elem.addEventListener(event, (e) => fn(e, elem), false);
        })
      }
    }

    let lastKnownScrollPosition = window.scrollY
    let ticking = true

    attachEvent('#header nav', 'click', function () {
      document.querySelector('[data-aw-toggle-menu]')?.classList.remove('expanded')
      document.body.classList.remove('overflow-hidden')
      document.getElementById('header')?.classList.remove('h-screen')
      document.getElementById('header')?.classList.remove('expanded')
      document.getElementById('header')?.classList.remove('bg-page')
      document.querySelector('#header nav')?.classList.add('hidden')
      document.querySelector('#header > div > div:last-child')?.classList.add('hidden')
    })

    attachEvent('[data-aw-toggle-menu]', 'click', function (_, elem) {
      elem.classList.toggle('expanded')
      document.body.classList.toggle('overflow-hidden')
      document.getElementById('header')?.classList.toggle('h-screen')
      document.getElementById('header')?.classList.toggle('expanded')
      document.getElementById('header')?.classList.toggle('bg-page')
      document.querySelector('#header nav')?.classList.toggle('hidden')
      document.querySelector('#header > div > div:last-child')?.classList.toggle('hidden')
    });

    let screenSize = window.matchMedia('(max-width: 767px)');
    screenSize.addEventListener('change', function () {
      document.querySelector('[data-aw-toggle-menu]')?.classList.remove('expanded')
      document.body.classList.remove('overflow-hidden')
      document.getElementById('header')?.classList.remove('h-screen')
      document.getElementById('header')?.classList.remove('expanded')
      document.getElementById('header')?.classList.remove('bg-page')
      document.querySelector('#header nav')?.classList.add('hidden')
      document.querySelector('#header > div > div:last-child')?.classList.add('hidden')
    });

    function applyHeaderStylesOnScroll() {
      const header = document.querySelector('#header[data-aw-sticky-header]');
      if (lastKnownScrollPosition > 60 && !header.classList.contains('scroll')) {
        document.getElementById('header').classList.add('scroll')
        document.getElementById('nav-btn-lang').classList.remove('bg-white/80')
        document.getElementById('nav-btn-login').classList.remove('bg-white/80')
      } else if (lastKnownScrollPosition <= 60 && header.classList.contains('scroll')) {
        document.getElementById('header').classList.remove('scroll')
        document.getElementById('nav-btn-lang').classList.add('bg-white/80')
        document.getElementById('nav-btn-login').classList.add('bg-white/80')
      }
      ticking = false
    }
    applyHeaderStylesOnScroll()

    attachEvent([document], 'scroll', function () {
      lastKnownScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(() => {
          applyHeaderStylesOnScroll()
        })
        ticking = true
      }
    })

    document.documentElement.classList.add('motion-safe:scroll-smooth')
    const elem = document.querySelector('[data-aw-toggle-menu]')
    if (elem) {
      elem.classList.remove('expanded')
    }
    document.body.classList.remove('overflow-hidden')
    document.getElementById('header')?.classList.remove('h-screen')
    document.getElementById('header')?.classList.remove('expanded')
    document.querySelector('#header nav')?.classList.add('hidden')
  }
})
</script>

<template>
  <div>
    <NuxtPage />
  </div>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tgi-font-sans: 'Inter';
  --tgi-font-serif: var(--tgi-font-sans);
  --tgi-font-heading: var(--tgi-font-sans);
  --tgi-color-primary: rgb(1 97 239);
  --tgi-color-secondary: rgb(1 84 207);
  --tgi-color-accent: rgb(109 40 217);
  --tgi-color-text-heading: rgb(0 0 0);
  --tgi-color-text-default: rgb(16 16 16);
  --tgi-color-text-muted: rgb(16 16 16 / 66%);
  --tgi-color-bg-page: rgb(255 255 255);
  --tgi-color-bg-page-accent: rgb(251, 251, 251);
  --tgi-color-bg-page-dark: rgb(3 6 32);

  ::selection {
    background-color: lavender;
  }
}

@layer utilities {
  .bg-page {
    background-color: var(--tgi-color-bg-page);
  }

  .bg-dark {
    background-color: var(--tgi-color-bg-page-dark);
  }

  .bg-light {
    background-color: var(--tgi-color-bg-page);
  }

  .bg-lighter {
    background-color: var(--tgi-color-bg-page-accent);
  }

  .text-page {
    color: var(--tgi-color-text-page);
  }

  .text-muted {
    color: var(--tgi-color-text-muted);
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center rounded-full border-gray-400 border bg-transparent font-medium text-center text-base text-page leading-snug transition py-3.5 px-6 md:px-8 ease-in duration-200 focus:ring-blue-500 focus:ring-offset-blue-200 focus:ring-2 focus:ring-offset-2 hover:bg-gray-100 hover:border-gray-600 cursor-pointer;
  }

  .btn-primary {
    @apply btn font-semibold bg-primary text-white border-primary hover:bg-secondary hover:border-secondary hover:text-white;
  }

  .btn-secondary {
    @apply btn;
  }

  .btn-tertiary {
    @apply btn border-none shadow-none text-muted hover:text-gray-900;
  }
}

#header.scroll>div:first-child {
  @apply bg-page md:bg-white/90 md:backdrop-blur-md;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
}

#header.expanded nav {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 70px !important;
  padding: 0 5px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

[data-aw-toggle-menu] path {
  @apply transition;
}

[data-aw-toggle-menu].expanded g>path:first-child {
  @apply -rotate-45 translate-y-[15px] translate-x-[-3px];
}

[data-aw-toggle-menu].expanded g>path:last-child {
  @apply rotate-45 translate-y-[-8px] translate-x-[14px];
}

.heroBackground {
  @apply bg-[url('/assets/images/safe-right2.svg')] bg-no-repeat;
  background-position: right bottom;

  @media (min-width: 1536px) {
    background-position: right bottom -60px;
  }

  /*
  // mobile
  @media (max-width: 768px) {
    background-position: right bottom -10px;
  }
  // tablet
  @media (min-width: 769px) and (max-width: 1024px) {
    background-position: right bottom -20px;
  }
  // laptop
  @media (min-width: 1023px) and (max-width: 1280px) {
    background-position: right bottom -30px;
  }
  */
}
</style>
